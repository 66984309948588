import React from 'react'
import './Hero.css';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-scroll';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import {
    useEffect,
    useState
} from 'react';


  
function Hero() {

const [loading, setLoading] = useState(true);

useEffect(() => {
    setLoading(false);
})

    return (
        <>
            {
                loading ? <img src="./loading.gif" alt="" /> :
                    <div id="home">
                        <div className="overLay"></div>
                        <div className="left__side_home">
                            <div id="desc" className="text">
                                <div className="supText">Our Vision</div>
                                <div className="heading">Accomplish your <span>Success</span> is our Mission</div>
                                <div className="subText">We help companies to get best employees and help job seekers to be placed in best companies. </div>
                                <Link to="contactUs"
                                    smooth={
                                        true
                                    }
                                    className="cta" >
                                    <div className="cta__icon"><FaArrowRight /></div>
                                    <div className="cta__text">Contact us</div>
                                </Link>
                            </div>
                        </div>
                        <OwlCarousel className='right__side_home'
                            autoplay="true"
                            loop
                            items="1"
                            autoplayTimeout="2000"
                            nav >
                            <div class='item'>
                                <img src="./images/hero-1.jpg" alt="Meeting" />
                            </div>
                            <div class='item'>
                                <img src="./images/hero.jpg" alt="Meeting" />
                            </div>
                            <div class='item'>
                                <img src="./images/hero-2.jpg" alt="Meeting" />
                            </div>
    
                        </OwlCarousel>

                    </div>
            }
        </>
    )
}

export default Hero
