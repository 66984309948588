import React from 'react'
import './How.css';
function How() {
    return (
        <div className="how" style={{
            backgroundImage: `url("./images/how_bg_2.png")`,
            backgroundPosition: "center ",
            backgroundSize: "cover",
            paddingBottom:"15px"
        }}>
            
            <div className="bottom">
                 <h1 class="how_heading" style={{
                backgroundImage: `url("./images/how_bg.png")`,
                backgroundPosition: "center ",
                backgroundSize: "cover"
                }}>How Does Recruitment process at iKare?</h1>

                <div className="proccess">
                    <div className="process__card">
                        <div className="process__cardImg">
                            <img src="./images/planning.svg" alt="planning"/>            
                        </div>
                        <div className="process__cardtext">
                            <h2>Planning</h2>
                            <p>Planning phase forms the mainstay of the recruitment process. Our team of experts will understand our requirements and specifications to identify your purpose, which in turn will build up our search. Based on your business needs, we will plan a strategy based on vital factors such as latest trends going on in the market, the level of competition, job profile etc. With our generated data, we work towards finding the right candidate for you.</p>
                        </div>

                    </div>
                    <div className="process__card">
                        <div className="process__cardImg">
                            <img src="./images/research.svg" alt="planning"/>            
                        </div>
                        <div className="process__cardtext">
                            <h2>Research</h2>
                            <p> In our research phase, we implement our strategy to find the suitable candidate based on the vital considerations of skill set, qualifications, stability, experience, and performance.This process also involves advertising the job vacancy along with the detailed job description on multiple job portals and ever - evolving social media channels.Next step includes selecting the relevant CV’ s based on the required parameters and sharing the best candidatures with our clients. </p>
                        </div>

                    </div>
                    <div className="process__card">
                        <div className="process__cardImg">
                            <img src="./images/screening.svg" alt="planning"/>            
                        </div>
                        <div className="process__cardtext">
                            <h2>Screening</h2>
                            <p> The preliminary screening round is an elimination round wherein we assess the selected applicants on key factors including job title, location, company profile, compensation package and other details.Our team of HR consultants conducts telephonic interviews to analyse the candidate’ s interest.This way, the client will interview only the best. </p>
                        </div>

                    </div>
                    <div className="process__card">
                        <div className="process__cardImg">
                            <img src="./images/interview.svg" alt="planning"/>            
                        </div>
                        <div className="process__cardtext">
                            <h2>Schedule Client Interviews </h2>
                            <p>Planning phase forms the mainstay of the recruitment process. Our team of experts will understand our requirements and specifications to identify your purpose, which in turn will build up our search. Based on your business needs, we will plan a strategy based on vital factors such as latest trends going on in the market, the level of competition, job profile etc. With our generated data, we work towards finding the right candidate for you.</p>
                        </div>

                    </div>
                    <div className="process__card">
                        <div className="process__cardImg">
                            <img src="./images/closuer.svg" alt="planning"/>            
                        </div>
                        <div className="process__cardtext">
                            <h2>Closuer</h2>
                            <p> The last phase of the hiring process involves the negotiation on remuneration where we Support the client on salary package, joining date and offer letter and communicate the same to the shortlisted candidate.We provide timely guidance both to our client and selected candidate to facilitate smooth engagement of the candidate in the organization. </p>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default How
