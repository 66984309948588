import React, { useState } from 'react'
import './Navbar.css';
import { Link } from 'react-scroll';
import {
    AiOutlineAppstore,
    AiFillMinusSquare,
    AiFillCaretRight
} from "react-icons/ai";
import DropDownMenu from './DropDownMenu';
function Navbar() {
    const [toogle, setToogle] = useState(false);
    const [dropDown, setDropDown] = useState(false);

    const scrollToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
        setToogle(!toogle)
    }

    const handleDropDown = () => {
        setDropDown(!dropDown);
        console.log(dropDown);
    }

    return (
        <>
           
        <div className="navbar">
            <div className="container nav-bar-container">
                <div className="nav__logo">
                        <img src="./images/logo.png" alt="Ikare" />
                        <div class="menu" onClick = {
                            () => setToogle(!toogle)
                        }>
                        {
                            toogle ? <AiFillMinusSquare className="color_black"/>:<AiOutlineAppstore  />
                         }
        
                    </div>
                </div>
                    <div className={
                        toogle ? "nav__liks-container d-block topBottom" : "nav__liks-container d-none "
                } >
                    <ul className="d-flex justify-content-evenly">
                        
                            <Link to="home"onClick={scrollToTop} className="topBarLink"><li>Home</li></Link>
                        <Link to="aboutus" smooth={true} onClick = {
                            () => setToogle(!toogle)
                        } offset={-50} className="topBarLink"><li>About us</li></Link>
                        <Link  smooth={true} offset={-50} className="topBarLink" onClick={handleDropDown}><li > <div>Our Services</div> 
                                {
                                    dropDown && <div className="dropDown" >
            <ul>
                <Link className="dropDownlink" to="hr" smooth={true} offset={-50} onClick={
                                        () => {setToogle(!toogle); setDropDown(!dropDown);}}><li>Hr Services</li></Link>
                <Link className="dropDownlink" to="ss" smooth={true} offset={-50} onClick={
                                        () => {setToogle(!toogle); setDropDown(!dropDown);}}><li>Staffing Services</li></Link>
                <Link className="dropDownlink" to="cs" smooth={true} offset={-50} onClick={
                                        () => {setToogle(!toogle); setDropDown(!dropDown);}}><li>Contract Services</li></Link>
           </ul>
        </div>   
                                }
                            </li>
                            </Link>
                        <Link to="ourClients" smooth={true} onClick = {
                            () => setToogle(!toogle)
                        } className="topBarLink"><li >  Our Clients 
                                
                            </li>
                            </Link>
                        <Link to="postResume" smooth={true} onClick = {
                            () => setToogle(!toogle)
                        } offset={-50} className="topBarLink"><li>Career</li></Link>
                        <Link to="contactUs"  smooth={true} onClick = {
                            () => setToogle(!toogle)
                        } className="topBarLink"><li>Contact us</li></Link>
                    </ul>
                </div>
                
            </div>
            </div>
           
            </>
    )
}

export default Navbar
