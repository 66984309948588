import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import OurClients from './components/OurClients';
import Services from './components/Services';
import PostCv from './components/PostCv';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import How from './components/How';
import Footer from './components/Footer';
import { useEffect, useState } from 'react';
import Loading from './components/Loading';




function App() {
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  })


  return (
    <div className="App">
      {
        loading ? <Loading /> :
          <>
     
        <Navbar />
      <Hero />
      <AboutUs  />
      <Services />
      <How />
      <OurClients />
      <PostCv />
      <ContactUs />
      <Footer />
        </>
      }
      
    </div>
  );
}

export default App;
