import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import './SubService.css'
import './Services.css';
import Servicestabs from "./Servicestabs";
import { useState } from "react";


const Services = () => {
  const [value, setValue] = useState(2);
  
  return (
    <div
           id="services"
          style = {
            {
                backgroundImage: `url("./images/service-bg.png")`,
                backgroundPosition: "center right",
                backgroundSize: "cover"
            }
        } 
    >
      <h4 className="heading__title">Our Services</h4>
      <div className="services">
        <div className="service" id="hr">
          <div className="serviceHeading">
            <h1>Hr Services</h1>

          </div>
          <div className="serviceDetails">
            <div className="listHeading">
              Our HR Services Includes:
            </div>
            <div className="list">
              <ul>
                <li>Outsourcing</li>
                <li>Permanent Staffing</li>
                <li>Temporary Staffing</li>
                <li>Contractual Staffing</li>
                <li>RPO</li>
                <li>Payroll Management</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="service" id="ss">
          <div className="serviceHeading">
            <h1>Staffing Services </h1>

          </div>
          <div className="serviceDetails">
            <div className="serviceAbout">
              iKare understands the importance of selecting the right candidates
              for any organization.Our Staffing services help to source the best candidates.Whether you are trying to fill a vacant position or trying to find a replacement
              for the organization iKare makes sure to match the candidates’ expectation with the framework of the organization and recruit the perfect applicant.
            </div>
            <div className="listHeading">
              Our Staffing Process:
            </div>
            
            <div className="list">
              <ol>
                <li>Manpower Planning</li>
                <li>Recruitment</li>
                <li>Selection</li>
                <li>Placement</li>
                <li>Training</li>
                <li>Development</li>
                <li>Promotion</li>
                <li>Transfer</li>
                <li>Appraisal</li>
                <li>Determination of Remuneration</li>
              </ol>
            </div>
            <div className="orderList">
              <ol>
                <li>
                  <div className="orderHeading">
                    Permanent Staffing :
                  </div>
                  <div className="orderListDetails">
                    A Permanent staffing specialist organization gathers the best fit candidates based on the bar of compensation, experience, job description and expected set of responsibilities to guarantee complete facility of solution. It is the way toward giving required candidates in long-term. iKare Permanent staffing service takes the responsibility for finding, filtering, screening, interviewing and placing the preciseapplicant with required talent in the organization.
<br />
<br />

We offer a wide range of staffing administrations, which also includes permanent staffing. In our permanent staffing, we provide the applicants who are an ideal fit for the required position. Our permanent staffing has a wide scale of IT, Non-IT , Retail , and Infrastructure solutions among others and we are pleased to state that for each ground we give the candidates who are perfect.

<br />
<br />

Our staff continues sourcing the applicants from the active and passive outer sources, however we keep up our own information database from which we source and find the candidates who are interviewed by our technical and talent acquisition team and are matching to the accessible opportunity requested by our client.

                  </div>

                </li>
                <li>
                  <div className="orderHeading">
                    Temporary Staffing:
                  </div>
                  <div className="orderListDetails">
                   We one of the leading staffing organizations, iKare Temporary Staffing Services offer the capacity to build your staff quality and strength without engrossing them
                   for full time that will help over - burden workforces during critical time, so you can fulfil your targets and keep assignments moving.We offer flexible temporary staffing services to your organization that fits perfectly with your requirements, and we ensure to provide the best candidates to your organization to fulfill your needs / requirements.

<br />
<br />
                   At the point when you recruit Ikare as your Temporary Staffing Agency, we would work like your organization 's own Human Resource (HR) Department, short the hassles, problems, and tediousness that you would in any case have to deal with. We actively screen, shortlist, and evaluate required applicants to give you the excellent picks

                  </div>

                </li>
              
              </ol>
            </div>
          </div>
        </div>
        <div className="service" id="cs">
          <div className="serviceHeading">
            <h1>Contract Staffing </h1>

          </div>
          <div className="serviceDetails">
            <div className="serviceAbout">
  Contract Staffing is a strategy
  for enlisting required human resource on contract.This agreement could happen between one organization and an individual or between two organizations.It helps organizations to rapidly increase endeavour groups with as few or many qualified experts as needed to fill short - term and long term requirements.
<br />
<br />
  ikare is one of the leading contract staffing service provider in India, we offer right temporary candidate with talent
  for your specific business prerequisites.Our team you get access to a skilled pool of candidates who will assist your organization with boosting business efficiency, fulfill time constraints, and drive results.

            </div>
           
           
            
          </div>
        </div>
        </div>
    </div>
  );
};
  
export default Services;