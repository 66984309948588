import "./footer.css";
import {
    Facebook, Instagram, WhatsApp
} from '@material-ui/icons/';
import {
    Link
} from 'react-scroll';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footerWrapper">
                <div className="footerTop">
                <div className="footerSocial">
                    <div className="footerLogo">
                        <img src="./images/logo.png" alt="IKARE Logo" className="footerImg" />
                    </div>
                    <div className="footerDescription">
                        <span>We help companies to get best employees and help job seekers to be placed in best companies.</span>
                        </div>
                        <div className="footerSocialTitle">
                            <span>Reach Us Out</span>
                        </div>
                    <div className="socialLinks">
                        <a href="#" className="link footerIcons"><Facebook/></a>
                        <a href="#" className="link footerIcons"><Instagram /></a>
                        <a href="#" className="link footerIcons"><WhatsApp /></a>
                    </div>
                </div>
                
            </div>
                <div className="footerBottom">
                    <div className="footerBottomTitle">
                        <span>Quick Links</span>
                    </div>
                    <div className="footerBottomLinks">
                        <div className="footerBottomLinkWrapper">
                            <div className="footerBottomLinkTitle">
                                <span>Basic</span>
                            </div>
                            <ul className="footerBottomLinkList">
                                <Link  smooth={true}  to="home" offset={-50} className="footerQuickLink" ><li>Home </li></Link>
                                <Link  smooth={true}  to="aboutus" offset={-50} className="footerQuickLink" ><li>About Us </li></Link>
                                <Link  smooth={true}  to="ourClients" offset={-50} className="footerQuickLink" ><li>Our Clients </li></Link>
                            </ul>
                        </div>
                        <div className="footerBottomLinkWrapper">
                            <div className="footerBottomLinkTitle">
                                <span>Services</span>
                            </div>
                            <ul className="footerBottomLinkList">
                                <li><Link to="ourservices" className="footerQuickLink">Hr Services</Link> </li>
                                <li>Staffing Services</li>
                                <li>Contract Staffing</li>
                            </ul>
                        </div>
                        <div className="footerBottomLinkWrapper">
                            <div className="footerBottomLinkTitle">
                                <span>Actions</span>
                            </div>
                            <ul className="footerBottomLinkList">
                                <li>Career </li>
                                <li>Contact Us</li>
                            </ul>
                        </div>
                    </div>
            </div>
            </div>
        </div>
    )
}

export default Footer
