import React from 'react'
import './OurClients.css';
function OurClients() {

    const clientPics = [
        {id:1,img:"./images/logo/1.png"},
        {id:2,img:"./images/logo/2.png"},
        {id:3,img:"./images/logo/3.png"},
        {id:4,img:"./images/logo/4.png"},
        {id:5,img:"./images/logo/5.png"},
        {id:6,img:"./images/logo/6.png"},
        {id:7,img:"./images/logo/7.png"},
    ]
 
    return (
        <>
            <div div className = "section__heading new__section"
            id = "ourClients"
            style={{
                backgroundImage: `url("./images/client-bg.png")`,
                backgroundPosition: "center ",
                backgroundSize: "cover"
                }}
             >
                <h2 >Our Clients</h2>
                <div className="clients">
                    
                    {clientPics.map((curEle)=>{ 
                        return (
                            
                    <div className="client__card">
                        <img src={curEle.img} alt="Ikare Logo" className="logos"/>
                    </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default OurClients
