import Lottie from "react-lottie";
import "./loading.css";
import * as loading from "../loading.json";


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
const Loading = () => {
    return (
        <div className="loading">
            {/* <Lottie options={defaultOptions} height={"60%"} width={"50%"} /> */}
            <img src="./images/logo.png" alt="" className="loading_logo" />
            <div class="loadingio-spinner-eclipse-ir0xk54kzr"><div class="ldio-a0kwqp8gqya">
<div></div>
</div></div>
        </div>
    )
}

export default Loading
