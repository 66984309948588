import React from 'react'
import axios from 'axios';
import './PostCv.css';
import { FaFileImport, FaRegPaperPlane, } from "react-icons/fa";
import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";


class PostCv extends React.Component {
    state = {
        file: null,
        loading: false,
        disabled:false
    } 
    handleFile(e) {
        let file = e.target.files[0];
       
            
    
        this.setState({ file: file });
      

      
        
    }
    handleUpload(e) {
        
        

         const condition = navigator.onLine ? 'online' : 'offline';
         if (condition === 'online') {
             fetch('//google.com', {
                 mode: 'no-cors',
             })
                 .then(() => {
                     let file = this.state.file;
if (file != null) {

    this.setState({
        loading: true
    });
    let formdata = new FormData();
    console.log(file);
    formdata.append("file", file);
    axios.post('http://127.0.0.1:8000/api/upload', formdata)
        .then(response => {
            this.setState({
                disabled: false,
                loading: false,
                file: null
            });
            Swal.fire({
                position: 'top-center',
                icon: 'success',
                title: 'Resume Sent SuccessFully !',
                showConfirmButton: false,
                timer: 2000
            });
        })
        .catch(error => {

            this.setState({
                disabled: false,
                loading: false,
                file: null
            });
            Swal.fire({
                position: 'top-center',
                icon: 'error',
                title: 'OOPs something went wrong try again',
                showConfirmButton: true,
                timer: 2000
            });
        })
} else {

    Swal.fire({
        position: 'top-center',
        icon: 'error',
        title: 'OOPs file not selected ',
        showConfirmButton: false,
        timer: 2000
    });
}

                 }).catch(() => {
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: 'No internet connection ',
                        showConfirmButton: false,
                        timer: 2000
                    });
                 })
        }
        
        
         
         
         
        
    }
    render (){
            return(

                <div  className="new__section" id="postResume">
                    <h1 className="heading__title">Career</h1>
                    
                    <form className="outer" >
                        <div className="col-5 ">
                        
                            <label htmlFor="file" className="choose__btn"><FaFileImport/> <span style={{marginLeft:10}}>Upload Resume</span></label>
                            <input type="file" id="file" hidden name="file" onChange={(e) => this.handleFile(e)} />
                            <br />
                            
                            <button id="uploadBtn" disabled={this.state.disabled} className={this.state.disabled ? "btn-submit disabled" : "btn-submit" } type="button"onClick={ (e)=> this.handleUpload(e) }> {this.state.loading ? <Loader type="ThreeDots" color="#FFFFFF" height={30} width={30}/>: <FaRegPaperPlane/>}</button>
                            </div>
                    </form>
                   
        </div>
    );
}
}

export default PostCv

