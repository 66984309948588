import React from 'react'
import './AboutUs.css';
// Ikare is a nice human resource consulting and staffing company. We providing recruitment services in IT, Web-Mobile Application development, Engineering, Construction, Finance, Back office Operations, Sales and Marketing, Manufacturing operations, Facilities and security Management, etc. we provide permanent placement services for almost all sectors from entry level to Top Level.


//  We innovative practices have helped our esteemed clients in maximizing their business opportunities and deliveries. We work closely with our clients, understanding their needs and challenges. Our moto is to facilitate enterprise resource planner packages and custom solutions backend by out of box thinking and maximum standard of clients’ service.
function AboutUs() {
    return (
        <div className = "about__section"
        id = "aboutus"
        style = {
            {
                backgroundImage: `url("./images/about-one-bg.png")`,
                backgroundPosition: "center ",
                backgroundSize: "cover"
            }
        } >
            <div className = "heading__title" >
                <h4 style={{textAlign : 'center'}}>About Us</h4>
            </div>
            <div className="about__container">
                    <div className="about__top d-flex ">
                <div className = "left__side text__top" >
                        <div>
                            <p>Ikare is a nice human resource consulting and staffing company. We providing recruitment services in IT, Web-Mobile Application development, Engineering, Construction, Finance, Back office Operations, Sales and Marketing, Manufacturing operations, Facilities and security Management, etc. we provide permanent placement services for almost all sectors from entry level to Top Level.</p>
                        </div>
                    </div>
                    <div className="right__side">
                        <img src="./images/aboutOne.jpg" alt="about Ikare"/>
                        </div>
                </div>
                    <div className = "about__bottom d-flex " >
                    <div className="right__side">
                        <img src="./images/aboutTwo.jpg" alt="about Ikare"/>
                        </div>
                <div div className = "left__side text__bottom" >
                        <div>
                            <p>We innovative practices have helped our esteemed clients in maximizing their business opportunities and deliveries. We work closely with our clients, understanding their needs and challenges. Our moto is to facilitate enterprise resource planner packages and custom solutions backend by out of box thinking and maximum standard of clients’ service.</p>
                        </div>
                    </div>
                </div>
                   
              
               
            </div>
        
        </div>
    )
}

export default AboutUs
