import React,{useState} from 'react'
import Swal from 'sweetalert2';
import axios from 'axios';
import './ContactUs.css';
function ContactUs() {

    const [values, setValues] = useState({
        name: "",
        email: "",
        phone:"",
        message:""
    });
    const [nameError, setNameError] = useState();
    const [emailError, setEmailError] = useState();
    const [phoneError, setPhoneError] = useState();
    const [messageError, setMessageError] = useState();
     
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({ ...values, [name]: value });

        

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        var letters = /^[a-zA-Z ]*$/;

        
        

        // name errors handling 
        if (values.name === "") {
            var nameErrros = 1;
            setNameError("Fullname field required");
        } else if (!values.name.match(letters)) {
            nameErrros = 1;
            setNameError("Fullname field accepts on alphabets");
        }else{
            nameErrros = 0;
            setNameError("");
        }
        
        // email errors handling 
        if (values.email === "") {
            var emailErros = 1;
            setEmailError("Email field required");
        } else if (!values.email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
            emailErros = 1;
            setEmailError("Email is invaild ");
        }else{
            emailErros = 0;
            setEmailError("");
        } 
        // phone errors handling 
        if (values.phone === "") {
             var phoneErros = 1;
            setPhoneError("Phone field is required ");
        } else if(values.phone.length > 10 || values.phone.length <10) {
            setPhoneError("Phone field should contain 10 digits ");
            phoneErros = 1;
        } else {
            phoneErros = 0;
            setPhoneError("");
        }
        // message errors handling 
        if (values.message !== "") {
            if (values.message.length > 5) {
                setMessageError("Message field should be maximum than 200 characters");
                var messageErros = 1;
            } else {
                messageErros = 0;
                setMessageError("");
            }
        } else {
            setMessageError("");
            messageErros = 0;
        }

        
       
        if (nameErrros === 0 && emailErros === 0 && messageErros === 0 && phoneErros === 0) {
            let formdata = new FormData();
            formdata.append("name", values.name);
            formdata.append("phone", values.phone);
            formdata.append("email", values.email);
            formdata.append("message", values.message);
            
            console.log(formdata);
            axios.post('http://127.0.0.1:8000/api/contact', formdata)
                .then((res) => {
                    setValues({
                        name: "",
                        email: "",
                        phone: "",
                        message: ""
                    });
                    console.log(res);
                    Swal.fire({
                        position: 'top-center',
                        icon: 'success',
                        title: res,
                        showConfirmButton: false,
                        timer: 2000
                    });
                })
                .catch((error) => {



                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: error,
                        showConfirmButton: true,
                        timer: 2000
                    });
                })
        } else{
            Swal.fire({
                position: 'top-center',
                icon: 'error',
                title: "Please fill details properly",
                showConfirmButton: true,
                timer: 1500
            });
        }
         


        




    }
    return (
        <div className="contact_container" style={
            {
                backgroundImage: `url("./images/service-bg.png")`,
                backgroundPosition: "center right",
                backgroundSize: "cover"
            }} id="contactUs"
        >
  <div style = {
      {
          textAlign: "center"
      }
  } >
    <h2 class="contact__heading">Contact Us</h2>
  </div>
  <div className="row">
    <div className="column img_column">
      <img src="./images/contact-bg.jpg" alt="" className="contact_img"/>
    </div>
    <div className="column contact_form">
      <form action="/action_page.php">
        <label for="name">Name</label>
        <input type="text" name="name"id="name" value={values.name} onChange={handleChange} placeholder="Name"/>
        <div className="error">{nameError}</div>
        <label for="email">Email</label>
        <input type="text" id="email" value={values.email} onChange={handleChange} name="email"  placeholder="Email" />
        <div className="error">{emailError}</div>
        <label for="phone">Phone</label>
        <input type="text" value={values.phone}  onChange={handleChange} name="phone" id="phone" placeholder="Phone"/>
        <div className="error">{phoneError}</div>
        <label for="message">Message</label>
        <textarea id="message" value={values.message} name="message" placeholder="Write something.." style={{height:170}}></textarea>
        <div className="error">{messageError}</div>
                        <input type="submit" value="Contact" onClick={ handleSubmit}/>
      </form>
    </div>
  </div>
</div>
    )
}

export default ContactUs
