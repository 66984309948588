import {
    FaAngellist,
    FaHouzz,
    FaHubspot
} from "react-icons/fa";
import {
    TiChartBarOutline,
    TiChartLineOutline,
    TiChartPie
} from "react-icons/ti";
import {
    VscCircuitBoard

} from "react-icons/vsc";
import {
    ImUserTie,
    ImGift,
    ImClipboard,
}
from "react-icons/im";

const dummyData = [{
        id: 0,
        title: "Hr Services",
        subTitle: "Our HR Services Includes",
        includes: ["Outsourcing", "Permanent Staffing", "Temporary Staffing", "Contractual Staffing", "RPO", "Payroll Management"]
    },
    {
        id: 1,
        title: "Staffing Services",
        desc: "iKare understands the importance of selecting the right candidates for any organization. Our Staffing services help to source the best candidates. Whether you are trying to fill a vacant position or trying to find a replacement for the organization iKare  makes sure to match the candidates’ expectation with the framework of the organization and recruit the perfect applicant. ",
        subTitle: "Our Staffing Process",
        incs: [{
                icon: <TiChartPie / > ,
                iconTitle: "Manpower Planning"
            },
            {
                icon: <FaAngellist className="icon" /> ,
                iconTitle: "Recruitment"
            },
            {
                icon: <ImClipboard className="icon" /> ,
                iconTitle: "Selection"
            },
            {
                icon: <FaHouzz className="icon" /> ,
                iconTitle: "Placement"
            },
            {
                icon: <FaHubspot className="icon" /> ,
                iconTitle: "Training"
            },
            {
                icon: <TiChartBarOutline className="icon" /> ,
                iconTitle: "Development"
            },
            {
                icon: <TiChartLineOutline className="icon" /> ,
                iconTitle: "Promotion"
            },
            {
                icon: <VscCircuitBoard className="icon" /> ,
                iconTitle: "Transfer"
            },
            {
                icon: <ImGift className="icon" /> ,
                iconTitle: "Appraisal"
            },
            {
                icon: <ImUserTie className="icon" /> ,
                iconTitle: "Determination of Remuneration"
            },

        ]

    }, {
        id: 2,
        title: "Contract Services",
        desc: "Contract Staffing is a strategy for enlisting required human resource on contract. This agreement could happen between one organization and an individual or between two organizations. It helps organizations to rapidly increase endeavour groups with as few or many qualified experts as needed to fill short-term and long term requirements.ikare is one of the leading contract staffing service provider in India, we offer right temporary candidate with talent for your specific business prerequisites.Our team you get access to a skilled pool of candidates who will assist your organization with boosting business efficiency, fulfill time constraints, and drive results.",
        parts: [
            {
                title: "Permanent Staffing :",
                description: "A Permanent staffing specialist organization gathers the best fit candidates based on the bar of compensation, experience, job description and expected set of responsibilities to guarantee complete facility of solution. It is the way toward giving required candidates in long-term. iKare Permanent staffing service takes the responsibility for finding, filtering, screening, interviewing and placing the preciseapplicant with required talent in the organization. We offer a wide range of staffing administrations, which also includes permanent staffing. In our permanent staffing, we provide the applicants who are an ideal fit for the required position. Our permanent staffing has a wide scale of IT, Non-IT , Retail , and Infrastructure solutions among others and we are pleased to state that for each ground we give the candidates who are perfect. Our staff continues sourcing the applicants from the active and passive outer sources, however we keep up our own information database from which we source and find the candidates who are interviewed by our technical and talent acquisition team and are matching to the accessible opportunity requested by our client."
            },
            {
                title: "Temporary Staffing :",
                description: "We one of the leading staffing organizations, iKare Temporary Staffing Services offer the capacity to build your staff quality and strength without engrossing them for full time that will help over-burden workforces during critical time, so you can fulfil your targets and keep assignments moving. We offer flexible temporary staffing services to your organization that fits perfectly with your requirements, and we ensure to provide the best candidates to your organization to fulfill your needs / requirements. At the point when you recruit Ikare as your Temporary Staffing Agency, we would work like your organization's own Human Resource (HR) Department, short the hassles, problems, and tediousness that you would in any case have to deal with. We actively screen, shortlist, and evaluate required applicants to give you the excellent picks."
            },
        ]

    }
];

export default  dummyData;